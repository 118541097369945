/** *
 * @param {Array|Object} elements - Element(s) of which a property is going to be trimmed.
 * @param {String} property - Name of the property inside each element that needs to be trimmed.
 * @param {Number} maxLength - Maximum string length that fits in the space.
 *
 * @returns {Array} New array of elements with specidfied props trimmed.
 */

const logger = require('nordic/logger');
const log = logger('api/error-handler');
const ASSETS_PATH = 'https://http2.mlstatic.com/frontend-assets/home-heimdall';

const ellipsisTrim = (items, property, maxLength) => {
  if (!items || !property || !maxLength) return [];

  const toTrim = Array.isArray(items) ? items : [items];

  return toTrim.map((item) => {
    const prop = item[property];

    if (prop && prop.length > maxLength) {
      item[property] = `${prop.slice(0, maxLength - 4).trim()}...`;
    }

    return item;
  });
};

const cleanUrl = (url, filteredParams = []) => {
  try {
    const newUrl = new URL(url);
    filteredParams.forEach(param => {
      newUrl.searchParams.delete(param);
    });
    return newUrl;
  }
  catch (err) {
    log.error(err, { stack: err.stack });
  }

};

const removeAccessTokensFromUrl = (url) => cleanUrl(url, ['accessToken', 'access_token', 'amp;accessToken']);

const addImageAssetPath = (image = '', extension = 'svg') => {
  if (!image) {
    return null;
  }
  try {
    return new URL(image).toString();
  } catch (error) {
    return `${ASSETS_PATH}/${image}.${extension}`;
  }
};

module.exports = {
  ellipsisTrim,
  cleanUrl,
  removeAccessTokensFromUrl,
  addImageAssetPath
};
