/* eslint-env browser */

const { I18n, I18nProvider } = require('nordic/i18n');
const React = require('react');
const { hydrateRoot } = require('nordic/hydrate');

module.exports = function startApp(Component) {
  const preloadedState = window.__PRELOADED_STATE__;

  const i18n = new I18n({ translations: preloadedState.translations });

  hydrateRoot(
    document.getElementById('root-app'),
    <I18nProvider i18n={i18n}>
      <Component {...preloadedState} />
    </I18nProvider>,
  );
};
